<div ngbDropdown #dropdown="ngbDropdown">

  <div ngbDropdownToggle class="toggle">

    <div class="comment-marker box-shadow-sm" [class.done]="comment.Done">
      <i *ngIf="comment.Done" class="fa-sharp fa-solid fa-circle-check"></i>
    </div>

    <!--<button id="sc-chatbot-trigger" [class.done]="comment.Done">
      <svg *ngIf="!comment.Done" class="sc-icon-chat" height="31" viewBox="0 0 33 31" width="33" xmlns="http://www.w3.org/2000/svg">
        <path d="m17 28.9v22.1277727c0 .4970563.4029437.9.9.9h4.6v7.0722273l9.625-7.0722273h16.975c.4970563 0 .9-.4029437.9-.9v-22.1277727c0-.4970563-.4029437-.9-.9-.9h-31.2c-.4970563 0-.9.4029437-.9.9z" fill="#fff" transform="translate(-17 -28)" />
      </svg>
      <fa-icon *ngIf="comment.Done" [icon]="['fas', 'check']" class="text-white fa-2x"></fa-icon>
    </button>-->
  </div>

  <div ngbDropdownMenu class="menu">

    <div class="p-3">

      <div class="row flex-nowrap">

        <div class="col">
          <ng-template let-item="item" #mentionListTemplate>

            <div class="row align-items-center">
              <div class="col-auto">
                <sc-avatar size="xs" [user]="item"></sc-avatar>
              </div>
              <div class="col-9 ms-n3">
                {{item.FirstName}} {{item.LastName}}
              </div>
            </div>

          </ng-template>
          <autosize-textarea class="comment-input d-block" [(text)]="input" [placeholder]="inputPlaceholder" [mention]="mentionItems" [mentionConfig]="mentionConfig" [mentionListTemplate]="mentionListTemplate"></autosize-textarea>
        </div>
        <div class="col-auto ps-0">
          <button class="btn btn-dark btn-circle send-button" [disabled]="!input || isSending" (click)="submit()">
            <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
          </button>
        </div>

      </div>

      <div class="row mt-3" *ngIf="activities?.length > 0">
        <div class="col-12">
          <div class="comment-feed mt-3 scrollbar-minimal">

            <div *ngFor="let activity of activities" class="mb-3">
              <sc-activity-item-comment [activity]="activity">
                <div class="comment-body">
                  <p [innerHTML]="activity.Payload.Body"></p>
                </div>
              </sc-activity-item-comment>
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col"></div>
        <div class="col-auto">
          <button *ngIf="comment.Done && isManager" class="btn btn-circle btn-secondary" (click)="reopen.emit()"><i class="fa-sharp fa-regular fa-xmark"></i></button>
          <button *ngIf="canDelete" class="btn btn-circle btn-secondary ms-2" (click)="delete.emit()"><i class="fa-sharp fa-solid fa-trash"></i></button>
          <button *ngIf="!comment.Done && isManager" class="btn btn-circle btn-dark ms-2" (click)="close.emit()"><i class="fa-sharp fa-solid fa-check"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>