import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService, ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppChatsService } from 'src/services/app-chats.service';
import { NotificationsService } from 'src/services/notifications.service';
import { UserService } from 'src/services/user.service';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeIn]
})
export class DashboardComponent implements OnInit, OnDestroy {

  state = 'out';

  destroy$ = new Subject<any>();

  intro: any;

  project: any;

  projects : any;

  notificationCount: any = 0;

  projectCount = 0;

  constructor(
    public userService : UserService,
    private api: ScApiService, 
    public notifications : NotificationsService, 
    private chatbot: AppChatsService) { }

  ngOnInit() {

    this.api.get('dashboard').subscribe(d => {
      this.intro = d;
      setTimeout(() => this.state = 'in', 50);
    }, e => this.api.redirectAnonymousToLogin(e));

    this.api.get('projects/latest')
      .subscribe((p : any) => {
        this.project = p;
        this.notificationCount = this.notifications.items.filter(i => i.Project?.Id == p.Id).length;
      });

      this.api.get('projects/count')
      .subscribe((p : any) => {
        this.projectCount = p.Total;
      });

      this.api.get(`projects/search?pageSize=5`).subscribe((d : any) => {
        this.projects = d.Projects;
        this.state = 'in';
      }, e => this.api.redirectAnonymousToLogin(e));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.chatbot.cancelBotChat();
  }

  getImageIndex(item)
  {
    return item.Id % 3 + 1;   
  }

  newProject()
  {    
    this.chatbot.newProjectChat();
  }

  showNotifications()
  {
    this.notifications.showModal();
  }
}
