import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-jobsite-nav',
  templateUrl: './jobsite-nav.component.html',
  styleUrls: ['./jobsite-nav.component.scss']
})
export class JobsiteNavComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  job : any;

  @ViewChild('nav', { static: true }) headerElement: ElementRef;
  @ViewChild('navContainer', { static: true }) headerContainerElement: ElementRef;


  @Output() activeItemChanged: EventEmitter<any> = new EventEmitter();
  @Input() menuItems = [
    { 'label': 'Aufgaben', 'anchor': 'aufgaben', 'active': true, 'animDirection': 'right' },
    { 'label': 'Gehalt + Benefits', 'anchor': 'gehalt-und-benefits', 'active': false, 'animDirection': 'right' },
    { 'label': 'Arbeitsumfeld', 'anchor': 'arbeitsumfeld', 'active': false, 'animDirection': 'right' },
    { 'label': 'Unternehmen', 'anchor': 'unternehmen', 'active': false, 'animDirection': 'right' }
  ];

  constructor(private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: string) { }


  ngOnInit() {

    if (isPlatformServer(this.platformId)) return;

    // this.scrollSpy.spyElement(this.headerContainerElement).subscribe((elementInViewport: any) => {

    //   window.requestAnimationFrame(() => {
    //     if (elementInViewport.top) {
    //       this.renderer2.removeClass(this.headerElement.nativeElement, 'fixed-top');
    //       this.headerContainerElement.nativeElement.style.height = this.headerElement.nativeElement.clientHeight + 'px';
    //     } else if (elementInViewport.isBelowViewport == false) {
    //       this.renderer2.addClass(this.headerElement.nativeElement, 'fixed-top');
    //     }
    //   });

    // });



  }

  ngOnChanges()
  {
    if (this.job?.EmploymentType == 'Freelancer') this.menuItems[1].label = 'Honorar + Benefits';
    else this.menuItems[1].label = 'Gehalt + Benefits';    
  }

  public initScrollSpy() {

    if (isPlatformServer(this.platformId)) return;

    setTimeout(() => {
      var menuElements = [];

      this.menuItems.forEach((item, index) => {
        menuElements.push(<HTMLElement>document.documentElement.querySelector('section#' + item.anchor));
      });
      // this.scrollSpy.spyNativeElements(menuElements).subscribe((activeIndex: any) => {
      //   this.setItemState(this.menuItems[activeIndex].anchor);
      // });
    }, 500);


  }

  ngAfterViewInit() {
    this.initScrollSpy();
  }

  itemClick(anchorID: string) {
    this.activateItem(anchorID);
  }

  activateItem(anchorID: string) {
    this.activeItemChanged.emit(anchorID);
  }

  setItemState(anchorID: string) {
    const activeItem = this.menuItems.filter(opt => opt.active)[0];
    const newItem = this.menuItems.filter(opt => opt.anchor === anchorID)[0];
    const activeIndex = this.menuItems.indexOf(activeItem);
    const newIndex = this.menuItems.indexOf(newItem);

    if (activeIndex === newIndex) { return; }

    if (activeIndex < newIndex) {
      activeItem.animDirection = 'right';
      newItem.animDirection = 'left';
    } else {
      activeItem.animDirection = 'left';
      newItem.animDirection = 'right';
    }

    activeItem.active = false;
    newItem.active = true;

  }

}
