import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-typewriting-paragraph',
  templateUrl: './typewriting-paragraph.component.html',
  styleUrls: ['./typewriting-paragraph.component.scss']
})
export class TypewritingParagraphComponent {

  @ViewChild('paragraph')
  textArea: ElementRef

  @Input()
  text = ''

  @Input()
  typeSpeed = 5;

  displayText = ''

  isTyping = true;

  ngOnInit() {
  }

  public showText(type = true) {
    setTimeout(() => {
      if (type) {
        this.type();
      }
      else {
        this.isTyping = false;
        this.displayText = this.text;
      }
    }, 500);

  }

  type() {

    var subject = new Subject<any>();

    var i = 0;

    this.isTyping = true;

    var typeNext = () => {

      if (i >= this.text.length) {
        this.isTyping = false;

        subject.next();
        subject.complete();

        return;
      }

      var typeTime = this.getRandomInt(50, 75);

      setTimeout(() => {
        this.displayText += this.text.substring(i, i + this.typeSpeed);
        i += this.typeSpeed;
        typeNext();
      }, typeTime);
    }

    typeNext();

    return subject;
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
