import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalWizardComponent, ScApiService } from '@sc-ui';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-jobsite-toolbar',
  templateUrl: './jobsite-toolbar.component.html',
  styleUrls: ['./jobsite-toolbar.component.scss']
})
export class JobsiteToolbarComponent {

  @ViewChild('jobContainer')
  jobContainer: ElementRef;

  @ViewChild('confirmModal')
  confirmModal : ModalWizardComponent;

  @Input()
  project: any;

  @Input()
  isInCommentMode = false;

  @Output()
  isInCommentModeChange = new EventEmitter<any>();

  showComments = false;

  isBrowser = false;

  comments: any = [];

  mentionItems: any = [];

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'Label',
    dropUp: true,
    mentionSelect: (item: any) => {
      return item.InsertionValue;
    }
  }
  unlisten: () => void;

  constructor(private renderer: Renderer2, private hostRef: ElementRef, @Inject(PLATFORM_ID) platformId: string, private api: ScApiService, 
  private router: Router, private route: ActivatedRoute, public user : UserService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (!this.isBrowser) return;
    this.route.params.subscribe((params: any) => {
      this.project.Id = params.id;

      this.api.get('projects/' + this.project.Id + '/review').subscribe((p: any) => {

        this.project = p;

        this.api.get('projects/' + this.project.Id + '/review/work-items').subscribe(a => {
          this.comments = a;
        });
      });
    });


  }

  addComment() {
    this.startCommentMode();
  }

  startCommentMode() {
    this.isInCommentMode = true;
    this.isInCommentModeChange.next(this.isInCommentMode);
    setTimeout(() => {
      this.unlisten = this.renderer.listen('document', 'click', (event) => {
        this.handleJobsiteClick(event);
      });
    }, 10);

  }

  endCommentMode() {
    if (this.unlisten) {
      this.unlisten();
    }

    this.unlisten = null;
    this.isInCommentMode = false;
    this.isInCommentModeChange.next(this.isInCommentMode);
  }

  abortComment() {
    this.endCommentMode();
  }

  deleteComment(item) {
    if (item.Id == 0) {
      var index = this.comments.indexOf(item);
      this.comments.splice(index, 1);
      return;
    }
    this.api.delete('projects/' + this.project.Id + '/review/work-items/' + item.Id).subscribe(() => {
      var index = this.comments.indexOf(item);
      this.comments.splice(index, 1);
    });
  }

  closeComment(item) {
    item.Done = true;
    this.api.put('projects/' + this.project.Id + '/review/work-items/', item).subscribe(() => { });
  }

  reopenComment(item) {
    item.Done = false;
    this.api.put('projects/' + this.project.Id + '/review/work-items/', item).subscribe(() => { });
  }

  handleJobsiteClick(e) {

    if (this.hostRef.nativeElement.contains(e.target)) {
      return;
    }

    this.endCommentMode();

    e.preventDefault();

    // Get the body dimensions
    const body = document.body;
    const bodyWidth = body.scrollWidth;
    const bodyHeight = body.scrollHeight;

    // Get the click position relative to the body
    const clickX = e.pageX;
    const clickY = e.pageY;

    // Calculate the percentage position
    const x = (clickX / bodyWidth) * 100;
    const y = (clickY / bodyHeight) * 100;

    var c = {
      Id: 0,
      X: Math.round(x),
      Y: Math.round(y),
      ProjectId: this.project.Id,
      justCreated: true
    };

    this.comments.push(c);
  }

  confirm() {

    this.api.post('projects/' + this.project.Id + '/review/confirm', null).subscribe(d => {
      this.project.Status = "AwaitingJobsiteRelease";
    });
  }

  requestRevision() {

    this.api.post('projects/' + this.project.Id + '/review/request-revision', null).subscribe(d => {
      this.project.Status = "AwaitingJobsiteChanges";
      this.router.navigateByUrl('/projects/' + this.project.Id);
    });
  }

  goToProject()
  {
    this.router.navigateByUrl('/projects/' + this.project.Id);
    this.confirmModal.dismiss();
  }

  goToDashboard()
  {
    this.router.navigateByUrl('/dashboard');
    this.confirmModal.dismiss();
  }

}
