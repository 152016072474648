<div class="scroll-dummy" *ngIf="state == 'out'"></div>

<div class="content" [@fadeIn]="state">

  <div class="container mb-5">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mb-0">Projekte</h2>
      </div>
      <div class="col-auto">

        <a class="btn btn-dark btn-round" (click)="briefingModal.start()">
          <i class="fa-sharp fa-solid fa-stars me-2"></i> Projekt starten
        </a>

      </div>
    </div>
  </div>

  <div class="container">

    <div class="row">
      <div class="col-12">

        <div class="card">

          <div class="card-header">

              <div class="input-group input-group-flush input-group-merge">

                <input type="search" name="q" [(ngModel)]="q" (ngModelChange)="searchSoon()" class="form-control search" placeholder="Suchen">

                <div class="input-group-prepend ">
                  <div class="input-group-text">
                    <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
                  </div>
                </div> 
              
              </div>
 
          </div>

          <div class="list-group list-group-flush">

            <a *ngFor="let item of projects" routerLink="/projects/{{item.Id}}" class="list-group-item list-group-item-action">

              <div class="item-row align-items-center">
                <div class="image-col">
                  <div class="image-container">
                    <img *ngIf="item.Image" [src]="item.Image | imageSize : 80 : 50" />
                    <img *ngIf="!item.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{item.Id % 3 + 1}}.jpg" class="card-img-top" />
                  </div>
                </div>

                <div class="body-col">
                  <h4 class="h5 mb-0">
                    {{item.Name}} <span class="small fw-normal">m|w|d</span>
                  </h4>

                  <p class="card-text small mb-0">
                    <span *ngIf="!item.Locations?.length">Unbekannter Standort</span>
                    <span *ngIf="item.Locations?.length == 1">{{item.Locations[0].City}}</span>
                    <span *ngIf="item.Locations?.length == 2">{{item.Locations[0].City}} + ein weiterer Standort</span>
                    <span *ngIf="item.Locations?.length > 2">{{item.Locations[0].City}} + {{ item.Locations.length - 1 }} weitere Standorte</span>
                  </p>
                </div>

                <div class="status-col">
                  <app-project-status-badge [project]="item"></app-project-status-badge>
                </div>

                <!--<div class="actions-col">
                  <span class="arrow right"></span>
                </div>-->

              </div>
            </a>

          </div>

          <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
            <div class="col-auto">
              <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>

<app-modal-briefing #briefingModal></app-modal-briefing>

<!--<app-footer-blob></app-footer-blob>-->