import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-jobsite-review',
  templateUrl: './jobsite-review.component.html',
  styleUrls: ['./jobsite-review.component.scss']
})
export class JobsiteReviewComponent implements OnInit {

  uri : SafeResourceUrl;

  constructor(private sanitizer : DomSanitizer, private api : ScApiService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.api.get(`projects/${params.id}/jobsite-review`).subscribe((d : any) => {
        var secret = d.Secret;
        var uri = `https://jobs.skillconomy.com/review/${ secret }`;
        this.uri = this.sanitizer.bypassSecurityTrustResourceUrl(uri);
      }, e => this.api.redirectAnonymousToLogin(e))
     
    });
  }

}
