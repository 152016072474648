<div class="container">

	<div class="row">
  
	  <div class="col-12 col-lg-10 offset-lg-1">
  
		<h2>
		  <span>{{ 'Ihr Arbeitsort' | localize }}</span>
		</h2>
	  
	  </div>
  
	</div>
  
  </div>
  
  <div #navContainer *ngIf="jobLocations.length > 1">

	<nav #nav class="bg-white">
  
	  <div class="container">
  
		<div class="row align-items-center">
  
		  <div class="col offset-lg-1">
  
			<ul class="nav nav-tabs nav-overflow header-tabs" #overflowList (scroll)="getScrollPosition()">
			  <li class="nav-item" *ngFor="let item of jobLocations; let i=index">
				<a id="navitem-{{item.container}}" (click)="renderRoute(item)" class="nav-link" [class.active]="item == selectedLocation" >
				  {{ item.City }}
				</a>
			  </li>
			</ul>
  
		  </div>
  
		</div>
  
	  </div>
  
	</nav>
  
  </div>
  
  
  <div class="container">
  
	<div class="row">
  
  
	  <div class="col-12 col-lg-10 offset-lg-1">
  
  
		<div id="joblocation" class="row">
  
  
			<div class="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">         
			  <p innerHTML="{{jobLocationName}}"></p>       
			</div>          
  
			<div class="col-auto" *ngIf="hasRoute==true">  
  
				<div class="row">
  
					<div class="col-auto d-none d-lg-block">
					  <i class="fa-sharp fa-solid fa-car me-n2"></i>
					</div>
  
					<div class="col-auto">
						<p>
						  <i class="fa-sharp fa-solid fa-car me-2 d-lg-none"></i>
						  <strong class="text-success">{{jobTravelTime}}</strong><br>
						  {{jobDistance}}
						</p>          
					</div>
  
				</div>      
  
			</div>
		</div>
	  </div>
	</div>
  
  </div>
  
  <div id="gmap" #gmap></div>