import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { EMailValidation, ModalWizardComponent, ModalWizardPageComponent, ScApiService, ScuiModalComponent } from '@sc-ui';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-project-base-user-modal',
  templateUrl: './project-base-user-modal.component.html',
  styleUrls: ['./project-base-user-modal.component.scss']
})
export class ProjectBaseUserModalComponent implements OnInit, OnChanges {

  @Input()
  project: any;

  users: any = [];

  projectUsers: any = []

  q = "";

  showAddEMail = false;

  isAddingByEMail = false;

  isSearching = false;

  @ViewChild('modal')
  userModal : ModalWizardComponent;

  @ViewChild('demoAccountModal')
  demoAccountModal : ScuiModalComponent;

  constructor(private api: ScApiService, private userService : UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.users = [];

    if (!this.project) return;

    this.api.get(`users`).subscribe(d => {
      this.users = d;
      this.updateUserStates();
    })

    this.api.get(`projects/${this.project.Id}/users`).subscribe(d => {
      this.projectUsers = d;
      this.updateUserStates();
    })
  }

  showUsersModal(page)
  {
    if (this.userService.me.IsDemo)
    {
      this.demoAccountModal.show();
      return;
    }

    this.userModal.show(page);
  }

  updateUserStates() {
    this.users.forEach(u => {
      u.isAdded = false;
      u.canAdd = true;

      var projectUser = this.projectUsers.find(u2 => u2.Id == u.Id);
      if (projectUser) {
        u.isAdded = true;
        u.canRemove = !projectUser.IsManager && !projectUser.IsDecider && !projectUser.IsSelf;
      }
    });

    this.projectUsers.forEach(u => {
      u.isAdded = true;
      u.canRemove = !u.IsManager && !u.IsDecider && !u.IsSelf;
    });
  }

  searchSoon() {
    this.showAddEMail = false;

    if (this['searchTimeout']) clearTimeout(this['searchTimeout']);
    this['searchTimeout'] = setTimeout(() => {

      this.isSearching = true;

      this.api.get('users?q=' + encodeURIComponent(this.q)).subscribe((d: any) => {
        this.users = d;
        this.updateUserStates();
        // this.searchPending = false;    
        if (d?.length == 0)
        {
          this.validateEMail();
        }  
        this.isSearching = false;
      })
    }, 500);

  }

  validateEMail()
  {
    console.log('validating ail');
    this.showAddEMail = EMailValidation.validate(this.q);
  }

  removeUser(args: any) {
    var user = args.user;

    this.api.delete('projects/' + this.project.Id + '/users/' + user.Id).subscribe(() => {
      var i = this.projectUsers.indexOf(user);
      this.projectUsers.splice(i, 1);
      args.confirm();
      this.updateUserStates();
    });
  }

  addUser(args: any) {
    var user = args.user;

    this.api.post('projects/' + this.project.Id + '/users', user).subscribe((d: any) => {
      user.isAdded = true;
      user.canRemove = true;
      this.projectUsers.push(user);
      args.confirm();
      this.updateUserStates();
    });
  }

  addByEMail()
  {
    this.isAddingByEMail = true;

    var user : any = {
      EMail : this.q
    };
    
    this.api.post('projects/' + this.project.Id + '/users', user).subscribe((u: any) => {
      u.isAdded = true;
      u.canRemove = true;
      this.projectUsers.push(u);
      this.users.push(u);     
      this.showAddEMail = false; 
      this.isAddingByEMail = false;
    });
  }

}
