import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ScApiService } from '@sc-ui';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-jobsite-comment',
  templateUrl: './jobsite-comment.component.html',
  styleUrls: ['./jobsite-comment.component.scss'],
  providers: [ NgbDropdown ]
})
export class JobsiteCommentComponent implements OnInit {

  @Input()
  project: any;

  @Input()
  comment: any;

  @Input()
  isManager = false;

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  close = new EventEmitter<any>();

  @Output()
  reopen = new EventEmitter<any>();

  isOpen = false;

  user: any;

  activities: any = [];

  mentionItems: any = [];

  isLoading = false;

  hasMoreItems = true;

  isSending = false;

  input = '';

  inputPlaceholder = 'Kommentieren';

  activitiesUri: string;

  mentionsUri: string;

  @ViewChild(NgbDropdown)
  dropdown;

  commentInput: string;

  canDelete = false;

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'FirstName',
    dropUp: true,
    mentionSelect: (item: any) => {
      return item.InsertionValue;
    }
  }

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
    
    if (this.comment.justCreated) {
      setTimeout(() => {
        this.dropdown.open();
        this.canDelete = true;
      }, 50);
    }
    else
    {
      this.loadItems();
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  submit() {

    this.isSending = true;

    var activity = {
      Content: '#commented',
      Payload: {
        Body: this.input
      }
    };

    if (this.comment.Id == 0)
    {
      this.api.post('projects/' + this.project.Id + '/review/work-items/', this.comment).subscribe((w : any) => {
        this.comment.Id = w.Id;
        this.postActivity(activity);
      });
    }

    else 
    {
      this.postActivity(activity);
    }
  }

  postActivity(a)
  {
    var uri = 'projects/' + this.project.Id + '/review/work-items/' + this.comment.Id + '/activities';

    this.api.post(uri, a).subscribe(d => {

      this.isSending = false;
      this.activities.splice(0, 0, d);
      this.input = '';
    });
  }

  loadItems() {

    this.isLoading = true;

    var uri = 'projects/' + this.project.Id + '/review/work-items/' + this.comment.Id + '/activities?pageSize=100';

    this.api.get(uri).subscribe((d: any) => {

      this.activities = d.Items;
      this.canDelete = !this.activities || this.activities.every(i => i.Author?.IsSelf);
      this.isLoading = false;
    })
  }

  loadMentionItems() {
    this.api.get('projects/' + this.project.Id + '/review/mentions').subscribe((d: any) => {
      this.mentionItems = d;
      if (this.mentionItems?.length) this.inputPlaceholder = '@ eingeben, um Personen zu erwähnen'
    });
  }

  updateCanDelete(items)
  {    
    this.canDelete = !items || items.every(i => i.Author?.IsSelf);
  } 
}

