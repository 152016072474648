import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[scBackOnClick]'
})
export class BackOnClickDirective {

  @HostListener('click', ["$event"])
  onClick(ev) {
    this.navigateBack();
  }
  
  constructor(private location : Location) { }

  navigateBack()
  {
    this.location.back();
  }

}
