<div class="row justify-content-center">

  <div class="col-12">

    <div class="card card-row shadow-light-lg lift">
      <div class="row no-gutters">
        <div class="col-12 col-md-5 bg-cover card-img-left" style="background-image: url('assets/img/ai.jpg');">

          <img src="assets/img/ai.jpg" alt="..." class="img-fluid d-md-none invisible">

          <div class="shape shape-right shape-fluid-y svg-shim text-white d-none d-md-block">
            <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M116 0H51v172C76 384 0 517 0 517v173h116V0z"></path>
            </svg>
          </div>

        </div>
        <div class="col-12 col-md-7 pl-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12 ps-4">
                <h3>
                  Recruiting-KI Preview
                </h3>

                <p class="mb-0">
                  Mit unserer wegweisenden KI und
                  ausgewiesenen Recruiting-Expert:innen
                  besetzen wir deine Fach- und
                  Führungspositionen. Wir begleiten dich als
                  Partner von der Analyse bis zur Einstellung.
                  Wir haben eine KI entwickelt, die Stellen und
                  Profile besser versteht als ein Mensch.
                  So reduzieren wir deinen Aufwand auf zehn
                  Minuten pro Stelle und übernehmen alles
                  Weitere für dich.
                </p>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">

              </div>
              <div class="col-auto">
                <!-- <button class="btn btn-round btn-secondary me-2">Mehr erfahren</button> -->
                <button class="btn btn-round btn-dark" (click)="start()"><i class="fa-sharp fa-solid fa-stars me-2"></i> Starten</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
