<div id="sc-salary-graph" class="mt-4 mb-4">
	<svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 452 260" xmlns="http://www.w3.org/2000/svg"
	  xmlns:xlink="http://www.w3.org/1999/xlink">
	  <mask id="a" fill="#fff">
		<path d="m.42783 0h451v186h-451z" fill="#fff" fill-rule="evenodd" />
	  </mask>
	  <g fill="none" fill-rule="evenodd">
		<g mask="url(#a)" transform="translate(0 28)">
		  <g transform="translate(-16 32)">
			<path d="m467.42783 4v150h-451.0000004v-27.5z" fill="#eef4fc" fill-rule="evenodd" />
			<path d="m2.343043 125.84401 477.179016-120.690457" fill="none" stroke="#0062d6" stroke-linecap="square"
			  stroke-width="2" transform="matrix(.9998477 -.01745241 .01745241 .9998477 -1.111138 4.216047)" />
		  </g>
		</g>
		<g fill="#0062d6">
		  <g>
			<g transform="translate(295)"><text class="value">
				<tspan x="0" y="45">{{maxValue}}</tspan>
			  </text>
			  <text class="label">
				<tspan x="1" y="13">{{maxLabel}}</tspan>
			  </text></g>
			<circle cx="331.683332" cy="95.683332" r="7.683332" />
		  </g>
		  <g>
			<g transform="translate(58 65)"><text class="value">
				<tspan x="0" y="45">{{minValue}}</tspan>
			  </text>
			  <text class="label">
				<tspan x="1" y="13">{{minLabel}}</tspan>
			  </text></g>
			<circle cx="94.683332" cy="160.683332" r="7.683332" />
		  </g>
		  <text class="note">
			<tspan text-anchor="middle" x="226" y="256">{{ 'relevante Erfahrung → ideales Profil' | localize }}</tspan>
		  </text>
		</g>
	  </g>
	</svg>
  
  </div>
  