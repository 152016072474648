import { Injectable } from '@angular/core';
import { ChatsService } from '@sc-ui-chatbot';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CandidateChatsComponent } from 'src/components/candidate-chats/candidate-chats.component';

@Injectable({
  providedIn: 'root'
})
export class AppChatsService {

  private _isInitialized = false;

  private _onStop = new Subject<any>();

  private _chatComponent: CandidateChatsComponent;

  chats: any = [];

  activeChats: any = [];

  unreadMessageCount: number = 0;

  constructor(private _chatsService: ChatsService) { }

  initialize()
  {
    if (this._isInitialized) return;
    this._isInitialized = true;
    this._chatsService.initialize();
    
    this._chatsService.getChats().subscribe((chats: any) => {
      this.chats = chats;
      this.chats.forEach(c => this.initializeChat(c));
      this.updateMessageCount();
      this.updateActiveChats();
    });
  }

  stop()
  {
    this.chats.length = 0;
    this._chatsService.stop();
    this._isInitialized = false;
    this._onStop.next();
  }

  registerComponent(component: CandidateChatsComponent) {
    this._chatComponent = component;
  }

  updateActiveChats() {
    this.activeChats = this.chats.filter(i => i.UnreadMessageCount > 0);

    var animateInTimeout = 0;

    this.activeChats.forEach(c => {
      setTimeout(() => c.show = true);
      animateInTimeout += 250;
    }, animateInTimeout);

    console.log(this.activeChats);
  }

  newProjectChat() {
    this._chatsService.getChat(`projects/new`).subscribe(chat => {
      this._chatComponent.showBotChat(chat);
    });
  }

  candidateDirectChat(candidateId) {
    var uri = `candidates/${candidateId}/chat`;
    this._chatsService.getChat(uri).subscribe(chat => {
      var contained = this.chats.some(c => c.Id == chat.Id);
      if (!contained) this.chats.splice(0, 0, chat);
      this._chatComponent.showDirectChat(chat);
    });
  }

  candidateChat(projectId, candidateId, name, showTriggerOnly = false) {
    var uri = `projects/${projectId}/candidates/${candidateId}/chats/${name}`;
    this._chatsService.getChat(uri).subscribe(chat => {
      this._chatComponent.showBotChat(chat);
    });
  }

  candidateFeedbackChat(projectId, candidateId, showTriggerOnly = false) {
    this.candidateChat(projectId, candidateId, 'feedback', showTriggerOnly);
  }

  candidateDecideChat(projectId, candidateId, showTriggerOnly = false) {
    this.candidateChat(projectId, candidateId, 'decide', showTriggerOnly);
  }

  candidateStatusChat(projectId, candidateId, showTriggerOnly = false) {
    this.candidateChat(projectId, candidateId, 'status', showTriggerOnly);
  }

  cancelBotChat() {
    this._chatComponent.cancelChatbot();
  }

  showOverview() {
    this._chatComponent.showOverview();
  }

  private initializeChat(c) {
    c.onMessageReceived.pipe(takeUntil(this._onStop)).subscribe((m: any) => {
      this.moveChatToTop(c);
      this.updateMessageCount();

      var changedToActive = this.activeChats.indexOf(c) < 0 && c.UnreadMessageCount > 0;
      if (changedToActive) {
        this.activeChats.push(c);
        setTimeout(() => { c.show = true; }, 500);
      }
    });
    c.onAcknowledged.subscribe(() => { this.updateMessageCount() });
  }

  private moveChatToTop(c) {
    var index = this.chats.indexOf(c);
    if (index < 0) return;
    this.chats.splice(index, 1);
    this.chats.splice(0, 0, c);
  }

  private updateMessageCount() {
    var count = 0;
    this.chats.forEach(c => count += c.UnreadMessageCount);
    this.unreadMessageCount = count;
  }
}
