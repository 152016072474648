<nav class="navbar navbar-light bg-white sc-topbar">

	<div class="container-fluid px-4">
  
	  <a class="navbar-brand mr-auto">
		<img class="navbar-brand-img sc-logo" id="logo" src="assets/logos/sc-logo.svg" />
	  </a>
  
	  <!-- <app-unsubscribe (optOutClick)="handleOptOutClick($event)"></app-unsubscribe> -->
  
	</div>
  
  </nav>