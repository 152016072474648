import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-announcement',
  templateUrl: './dashboard-announcement.component.html',
  styleUrls: ['./dashboard-announcement.component.scss']
})
export class DashboardAnnouncementComponent {

  posts: any = [];

  categories: any = [];

  category: any = '';

  page = 1;

  totalPages = 1;

  pageSize = 1;

  @Output()
  startClick = new EventEmitter<any>();

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    
  }

  start()
  {
    this.startClick.next();
  }

}
