 

<div class="content" *ngIf="user">

  <div class="container mb-5">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-serif">Einstellungen</h1>
      </div>
    </div>
  </div>

  <div class="container mb-5">

    <div class="row">

      <div class="col">

        <ul class="nav nav-tabs">

          <li class="nav-item">
            <a class="nav-link" (click)="show = 'profile'" [class.active]="show == 'profile'">
              Profil
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="show = 'notifications'" [class.active]="show == 'notifications'">
              Benachrichtigungen
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="show = 'password'" [class.active]="show == 'password'">
              Passwort
            </a>
          </li>

        </ul>


      </div>

    </div>

  </div>

  <div class="container">

    <div class="row">

      <!-- Profil -->

      <div class="col-12" *ngIf="show == 'profile'">

        <div class="card card-xl">
          <div class="card-header border-0">
            <h2 class="mb-0">Profil</h2>
          </div>
          <div class="card-body">

            <div class="row">

              <div class="col-lg-8">

                <div class="form-group image-upload">

                  <sc-file-button #fileButton class="form-control pe-3" (filesChanged)="upload($event)">
    
                    <div class="row">
    
                      <div class="col-auto">
    
                        <sc-avatar [user]="user" size="xs"></sc-avatar>
    
                      </div>
    
                      <div class="col ms-n3">
    
                        <div *ngIf="!isUploadingImage" class="text-muted">Bild auswählen</div>
                        <div *ngIf="isUploadingImage">
                          <span class="spinner-border spinner-border-sm me-2 text-primary" role="status" aria-hidden="true"></span>
                          Bild wird hochgeladen...
                        </div>
    
                      </div>
    
                    </div>
    
                  </sc-file-button>
    
                  <div *ngIf="user?.Image" class="remove-image" (click)="clearImage()">
                    <i class="fa-regular fa-times"></i>
                  </div>
    
                </div>
    

              </div>

            </div>
              
            <div class="row">

              <div class="col-lg-8">
                <div class="form-group">

                  <!-- Label -->
                  <label class="form-label">Vorname</label>
                  <div class="input-group input-group-merge">
                    <input [(ngModel)]="user.FirstName" type="text" class="form-control">
                  </div>
    
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-lg-8">
                <div class="form-group">

                  <!-- Label -->
                  <label class="form-label">Nachname</label>
                  <div class="input-group input-group-merge">
                    <input [(ngModel)]="user.LastName" type="text" class="form-control">
                  </div>
    
                </div>

              </div>

            </div>

            
            <div class="row">

              <div class="col-lg-8">

                <div class="form-group">
                  <label class="form-label">E-Mail</label>
                  <div class="input-group input-group-merge">
                    <input name="eMail" [(ngModel)]="user.EMail" disabled type="text" class="form-control">
                  </div>
                </div>

              </div>


            </div>


            <div class="row align-items-center" *ngIf="saveSuccess">
              <div class="col">
                <div class="alert alert-success mt-4" role="alert">
                  <p class="alert-heading mb-0">
                    <span class="icon me-3">
                      <i class="fa-sharp fa-solid fa-check"></i>
                    </span>    
                    Die Daten wurden erfolgreich übernommen.
                  </p>
                </div>
              </div>
            </div>
            
            <button class="btn btn-primary mt-4" *ngIf="!saveSuccess" [disabled]="isSaving" (click)="save()">
              <span *ngIf="isSaving" class="spinner-border spinner-border-sm"></span>
              Übernehmen
            </button>

            <!-- <button class="btn btn-success mt-4" disabled *ngIf="saveSuccess">
              <i class="fe fe-check"></i> Übernommen
            </button> -->


          </div>
        </div>

      </div>

      <!-- Benachrichtigungen -->

      <div class="col-12" *ngIf="show == 'notifications'">

        <div class="card card-xl">
          <div class="card-header border-0">
            <h2 class="mb-0">Benachrichtigungen</h2>
          </div>
          <div class="card-body">

            <p class="mb-5">Legen Sie fest, wofür Sie E-Mail-Benachrichtungen erhalten möchten.</p>

              <div class="custom-control mb-3 custom-checkbox">
                <input type="checkbox" class="custom-control-input" disabled checked readonly>
                <label class="custom-control-label" for="customCheck3">Aufgaben und Erwähnungen</label>
              </div>

              <div class="custom-control mb-3 custom-checkbox">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="settings.SendAllNotifications" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Alle Aktivitäten</label>
              </div>

              <div class="row align-items-center" *ngIf="saveNotificationsSuccess">
                <div class="col">
                  <div class="alert alert-success mt-4" role="alert">
                    <p class="alert-heading mb-0">
                      <span class="icon me-3">
                        <i class="fa-sharp fa-solid fa-check"></i>
                      </span>    
                      Ihre Einstellungen wurden erfolgreich übernommen.
                    </p>
                  </div>
                </div>
              </div>

              <button class="btn btn-primary mt-4" *ngIf="!saveNotificationsSuccess" [disabled]="isSavingNotifications" (click)="saveNotifications()">
                <span *ngIf="isSavingNotifications" class="spinner-border spinner-border-sm"></span>
                Übernehmen
              </button>

          </div>
        </div>

      </div>

      <!-- Passwort -->

      <div class="col-12" *ngIf="show == 'password'">

        <div class="card card-xl">

          <div class="card-header border-0">
            <h2 class="mb-0">Passwort</h2>
          </div>
          
          <div class="card-body">

            <div class="row">
              <div class="col-lg-8">
                <div class="form-group">

                  <!-- Label -->
                  <label class="form-label">Altes Passwort</label>
                  <div class="input-group input-group-merge">
                    <input [(ngModel)]="oldPassword" type="password" class="form-control" autocomplete="current-password">
                  </div>
    
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-8">
                <div class="form-group">

                  <!-- Label -->
                  <label class="form-label">Neues Passwort</label>
                  <div class="input-group input-group-merge">
                    <input [(ngModel)]="newPassword" type="password" class="form-control" autocomplete="new-password">
                  </div>
    
                </div>
              </div>
            </div>



            <div class="row align-items-center" *ngIf="changePasswordError">
              <div class="col">
                <div class="alert alert-error" role="alert">
                  <p class="alert-heading mb-0">{{changePasswordError}}</p>
                </div>
              </div>
            </div>

            <div class="row align-items-center" *ngIf="changePasswordSuccess">
              <div class="col">
                <div class="alert alert-success mt-4" role="alert">
                  <p class="alert-heading mb-0">
                    <span class="icon me-3">
                      <i class="fa-sharp fa-solid fa-check"></i>
                    </span>    
                    Das Passwort wurde erfolgreich übernommen.
                  </p>
                </div>
              </div>
            </div>
            

            <button class="btn btn-primary mt-4" *ngIf="!changePasswordSuccess" [disabled]="isChangingPassword" (click)="changePassword()">
              <span *ngIf="isChangingPassword" class="spinner-border spinner-border-sm"></span>
              Übernehmen
            </button>

          </div>
        </div>

      </div>


    </div>

  </div>


</div>

