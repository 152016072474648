<sc-headroom upTolerance="5" downTolerance="5" innerClassName="sc-header">
  <nav #nav class="navbar navbar-primary navbar-expand-lg bg-primary sc-topbar">

    <a routerLink="/dashboard" class="navbar-brand me-auto">
      <img class="navbar-brand-img sc-logo" id="logo" alt="skillconomy Logo" src="assets/logo/skillconomy-logo-weiss.svg" />
    </a>

    <ul class="navbar-nav me-lg-5 d-none d-lg-flex">
      <li class="nav-item"><a class="nav-link" routerLink="/dashboard" routerLinkActive="is-active">Dashboard</a></li>
      <li class="nav-item" *ngIf="projectCount"><a class="nav-link" routerLink="/projects" routerLinkActive="is-active">Projekte</a></li>
    </ul>

    <div class="navbar-user">     

      <span *ngIf="appChats.chats.length" class="me-4 notifications"  (click)="appChats.showOverview()">
        <span class="icon">
          <i class="fa-sharp fa-solid fa-comment"></i>
        </span>
        <span *ngIf="appChats.unreadMessageCount" class="notification-counter badge badge-pill badge-danger">{{ appChats.unreadMessageCount }}</span>
      </span>

      <span class="me-4 notifications" (click)="showNotifications()">
        <span class="icon">
          <i class="fa-sharp fa-solid fa-bell"></i>
        </span>
        <span *ngIf="notifications.items?.length" class="notification-counter badge badge-pill badge-danger">{{ notifications.items?.length }}</span>
      </span>

      <div ngbDropdown class="d-none d-lg-flex">

        <div ngbDropdownToggle class="user">
          <sc-avatar size="xs" [user]="userService.me"></sc-avatar>
        </div>

        <div ngbDropdownMenu class="dropdown-menu-right">
          <a ngbDropdownItem routerLink="/settings" class="dropdown-item">
            <span class="icon me-2">
              <i class="fa-sharp fa-solid fa-gear "></i>
            </span>
            Einstellungen
          </a>
          
          <a ngbDropdownItem (click)="logout()" class="dropdown-item">
            <span class="icon me-2">
              <i class="fa-sharp fa-solid fa-arrow-right-from-bracket"></i>
            </span>
            Logout
          </a>
        </div>

      </div>

      <div ngbDropdown class="d-lg-none">

        <span ngbDropdownToggle class="notifications">
          <button id="sc-nav-toggle">
            <svg version="1.1" id="sc-hamburger" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 26" enable-background="new 0 0 26 26" xml:space="preserve">
              <g>
                <g id="bar-bottom">
                  <path id="dot" fill="#FFFFFF" d="M3.14,22.05c0.98-0.01,1.76-0.81,1.76-1.79c-0.01-0.98-0.8-1.77-1.78-1.76
                    c-0.98,0-1.77,0.8-1.77,1.78c0,0.47,0.19,0.93,0.53,1.26C2.21,21.87,2.67,22.06,3.14,22.05" />
                  <rect id="bar" x="8.6" y="18.5" fill="#FFFFFF" width="16.64" height="3.55" />
                </g>
                <rect id="bar-mid" x="0.76" y="11.06" fill="#FFFFFF" width="24.49" height="3.55" />
                <rect id="bar-top" x="0.76" y="3.94" fill="#FFFFFF" width="24.49" height="3.55" />
              </g>
            </svg>
          </button>
        </span>

        <div ngbDropdownMenu class="mobile-nav-dropdown dropdown-menu-right">

          <a ngbDropdownItem routerLink="/dashboard" routerLinkActive="is-active" class="dropdown-item">
            <span class="icon me-2">
              <i class="fa-sharp fa-sharp fa-solid fa-square-poll-horizontal"></i>
            </span>
            Dashboard
          </a>

          <a ngbDropdownItem routerLink="/projects" routerLinkActive="is-active" class="dropdown-item" *ngIf="projectCount">
            <span class="icon me-2">
              <i class="fa-regular fa-window-restore"></i>
            </span>
            Projekte
          </a>

          <a ngbDropdownItem routerLink="/settings" class="dropdown-item">
            <span class="icon me-2">
              <i class="fa-sharp fa-sharp fa-solid fa-gear"></i>
            </span>
            Einstellungen
          </a>

          <a ngbDropdownItem  (click)="logout()" class="dropdown-item">
            <span class="icon me-2">
              <i class="fa-sharp fa-solid fa-arrow-right-from-bracket"></i>
            </span>
            Logout
          </a>
        </div>

      </div>

    </div>

  </nav>

</sc-headroom>

 <app-notifications-modal [items]="notifications.items"></app-notifications-modal>