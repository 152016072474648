import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopnavigationComponent } from './layouts/topnavigation/topnavigation.component';
import { VerticallyCenteredComponent } from './layouts/vertically-centered/vertically-centered.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { LoginComponent } from './views/login/login.component';
import { ForgotPwComponent } from './views/forgot-pw/forgot-pw.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ProjectComponent } from './views/project/project.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { CandidateComponent } from './views/candidate/candidate.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { SettingsComponent } from './views/settings/settings.component';
import { RegisterComponent } from './views/register/register.component';
import { JobsiteReviewComponent } from './views/jobsite-review/jobsite-review.component';
import { EmptyComponent } from './layouts/empty/empty.component';
import { PoolsComponent } from './views/pools/pools.component';
import { PoolComponent } from './views/pool/pool.component';
import { PoolGroupComponent } from './views/pool-group/pool-group.component';
import { RegisterForbiddenComponent } from './views/register-forbidden/register-forbidden.component';
import { RegisterSendLinkComponent } from './views/register-send-link/register-send-link.component';
import { JobsiteComponent } from './views/jobsite/jobsite.component';


const routes: Routes = [
  {
    path: '',
    component: VerticallyCenteredComponent,
    children: [      
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },  
      {
        path: 'register/:secret',
        component: RegisterComponent
      },  
      {
        path: 'register-send-link',
        component: RegisterSendLinkComponent
      },  
      {
        path: 'register-forbidden',
        component: RegisterForbiddenComponent
      },  
      {
        path: 'login',
        component: LoginComponent
      },  
      {
        path: 'forgot-pw',
        component: ForgotPwComponent
      }
    ]
  },
  {
    path: '',
    component: TopnavigationComponent,
    children: [      
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },  
      {
        path: 'imprint',
        component: ImprintComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },     
      {
        path: 'dashboard',
        component: DashboardComponent, 
        data: {animation: 'dashboard'}
      }, 
      {
        path: 'projects',
        component: ProjectsComponent, 
        data: {animation: 'projects'}
      },    
      {
        path: 'projects/:id',
        component: ProjectComponent,
        data: {animation: 'project'}
      },
      {
        path: 'pools',
        component: PoolsComponent, 
        data: {animation: 'pools'}
      },    
      {
        path: 'pools/:id',
        component: PoolComponent,
        data: {animation: 'pool'}
      },    
      {
        path: 'pools/:poolId/groups/:id',
        component: PoolGroupComponent,
        data: {animation: 'pool'}
      },          
      {
        path: 'projects/:projectId/candidates/:id',
        component: CandidateComponent
      },          
      {
        path: 'pools/:poolId/candidates/:id',
        component: CandidateComponent
      },          
      {
        path: 'candidates/:id',
        component: CandidateComponent
      }, 
      {
        path: 'settings',
        component: SettingsComponent
      }
    ]
  },
  {
    path: '',
    component: EmptyComponent,
    children: [
      {
        path: 'projects/:id/jobsite',
        component: JobsiteComponent
      },
      {
        path: 'projects/:id/jobsite-review',
        component: JobsiteReviewComponent,
        data: {animation: 'project'}
      }
    ]

  },
  {
    path: '', 
    component: VerticallyCenteredComponent,
    children: [      
      {
        path: '**',
        redirectTo: '/404',
      },  
      {
        path : '404',
        component : NotFoundComponent
      }
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
