<div class="review-topbar box-shadow">

  <div class="container" *ngIf="project">

    <div class="row mt-2 mb-2 align-items-center justify-content-between">
      <div class="col-auto">
        <a routerLink="/projects/{{ project.Id }}" class="link-arrow left">Zur Projektseite</a>
        <!-- <a class="text-black" routerLink="/projects/{{ project.Id }}">
          <i class="fa-sharp fa-solid fa-chevron-left"></i>
        </a> -->
      </div>

      <div class="col-auto">

        <button *ngIf="!isInCommentMode" class="btn btn-round btn-secondary btn-yellow me-2" (click)="addComment()">
          <i class="fa-sharp fa-regular fa-comment-middle me-2"></i> Anmerkung erstellen
        </button>

        <button *ngIf="isInCommentMode" class="btn btn-round btn-dark me-2" (click)="abortComment()">
          Anmerkung erstellen <i class="fa-sharp fa-solid fa-circle-xmark ms-2"></i>
        </button>

        <button class="btn btn-round btn-dark" (click)="endCommentMode(); confirmModal.show(startPage)">
          <i class="fa-sharp fa-solid fa-stars me-2"></i> Ansprache starten
        </button>

      </div>

    </div>

  </div>
</div>

<sc-modal-wizard size="xl" #confirmModal>

  <sc-modal-wizard-page #startPage>

    <sc-modal-header>
      <h5 class="modal-title">Fortfahren?</h5>
    </sc-modal-header>

    <sc-modal-body>

      <div class="row align-items-center mt-7 mb-7">
        <div class="col-5 px-5 d-flex justify-content-center">

          <img src="/assets/img/plane.jpg" class="img-fluid w-100" />

        </div>
        <div class="col-6">
          <h2>Die Ansprache kann starten!</h2>
          <p>
            Es ist alles bereit, um mit der Ansprache passender Kandidat:innen zu starten! Wenn du mit dem Anforderungsprofil und
            der Jobsite zufrieden bist, geht es jetzt folgendermaßen weiter: Unsere KI führt eine detaillierte Bewertung der infragekommenden
            Profile durch und wählt die besten aus. Unsere Recruiting-Expert:innen prüfen abschließend noch einmal, ob alles
            zusammenpasst: Das Anforderungsprofil, die Jobsite und die Profil-Shortlist. Wenn sie final grünes Licht geben,
            werden die Kandidat:innen von unserer KI angesprochen und können sich über ihre persönliche Jobsite bewerben.
          </p>

          <div>
            <div class="card mt-5">
              <div class="card-body">
                <strong>Bestellung</strong>
                <p>
                  Mit dem Beginn der Ansprache bestellst du verbindlich die Durchführung des Projekts
                  gemäß den vereinbarten Konditionen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </sc-modal-body>

    <sc-modal-footer>
      <div class="container-lg">
        <div class="row align-items-center">
          <div class="col">
            <span class="text-muted" *ngIf="user.me?.IsDemo">Demo-Account: Ansprache nicht möglich</span>
          </div>
          <div class="col-auto">
            <button class="btn btn-round btn-dark" [disabled]="user.me?.IsDemo" (click)="confirm(); confirmModal.navigateTo(confirmationPage);">
              <i class="fa-sharp fa-solid fa-stars me-2"></i> Ansprache starten
            </button>
          </div>
        </div>
      </div>
    </sc-modal-footer>

  </sc-modal-wizard-page>

  <sc-modal-wizard-page #confirmationPage>

    <sc-modal-header>
      <h5 class="modal-title">&nbsp;</h5>
    </sc-modal-header>

    <sc-modal-body class="confirmation-modal-body">
      <img class="confirmation-blob" src="/assets/blobs/blob-1.svg" />
      <div class="row align-items-center h-100">

        <div class="col-6 offset-5">
          <h2>Vielen Dank!</h2>
          <p>
            Unsere Recruiter:innen beginnen nun mit der Überprüfung deiner Stelle
            und geben sie zur Ansprache durch die KI frei. Bewerbungen werden wir dir hier über das Portal
            zukommen lassen. Du wirst über neue Bewerbungen per E-Mail benachrichtigt. Danke für dein Vertrauen!
          </p>

        </div>
      </div>

    </sc-modal-body>

    <sc-modal-footer>
      <div class="container-lg">
        <div class="row align-items-center">
          <div class="col">
          </div>
          <div class="col-auto">
            <button class="btn btn-round btn-secondary" (click)="goToProject()">
              Zur Projektseite
            </button>
            <button class="btn btn-round btn-secondary ms-2" (click)="goToDashboard()">
              Zum Dashboard
            </button>
          </div>
        </div>
      </div>
    </sc-modal-footer>

  </sc-modal-wizard-page>

</sc-modal-wizard>

<app-jobsite-comment *ngFor="let c of comments" (delete)="deleteComment(c)" (close)="closeComment(c)" (reopen)="reopenComment(c)" [project]="project" [comment]="c" [isManager]="project.IsManager" [accessSecret]="accessSecret" [style.left]="c.X + '%'" [style.top]="c.Y +'%'"></app-jobsite-comment>
