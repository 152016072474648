import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService, EventService } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-modal-briefing',
  templateUrl: './modal-briefing.component.html',
  styleUrls: ['./modal-briefing.component.scss']
})
export class ModalBriefingComponent {

  @ViewChild('briefingModal')
  briefingModal: ModalWizardComponent

  @ViewChild('chatPage')
  chatPage: ModalWizardPageComponent

  @ViewChild('demoPage')
  demoPage: ModalWizardPageComponent

  chat: any;

  profiles: any = []

  constructor(public user: UserService, private chats: ChatsService, private router: Router, private events: EventService) { }

  start() {

    if (this.user.me.IsDemo)
    {
      this.briefingModal.show(this.demoPage);
      return;
    }

    this.chats.getChat('projects/new').subscribe(chat => {
      this.chat = chat;
    });

    this.briefingModal.show(this.chatPage);
    this.profiles = []
    localStorage.removeItem('briefing_state');
  }

  onBriefingComplete(message) {
    this.router.navigateByUrl('projects/' + message.Payload.Data.ProjectId + '/jobsite?reveal=true');
    this.briefingModal.dismiss();
  }

  saveState() {
    var data = {
      profiles: this.profiles
    }

    var dataJson = JSON.stringify(data);
    localStorage.setItem('briefing_state', dataJson);
  }

}
