import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'sc-chatbot-answer-range',
  templateUrl: './answer-range.component.html',
  styleUrls: ['./answer-range.component.scss']
})
export class AnswerRangeComponent implements OnChanges {

  @Input()
  message : any;


  ngOnChanges()
  {
  }

}
