<div class="scroll-dummy" *ngIf="!intro"></div>

<div class="content" [@fadeIn]="state">
  <!-- <div class="container intro-container">

    <div class="row align-items-center mb-3 mb-md-0" *ngIf="intro">

      <div class="col-7 col-md pe-lg-5">

        <h1 class="h2 mb-md-4 heading-serif">
          {{ intro.Salutation }} <br> {{ intro.FirstName }} <span class="d-none d-md-inline-block">{{ intro.LastName }}</span>
        </h1>

        <app-notification class="d-none d-md-block" *ngIf="notifications.projects?.length" (click)="showNotifications()" [badge]="notifications.items?.length" ctaLabel="Aufgaben ansehen">
          Ihr Einsatz ist gefragt!
        </app-notification>

      </div>

      <div class="col-md-auto col-4">
        <img class="img-fluid" src="assets/illus/skillconomy-bot-presenting.svg">
      </div>

    </div>

    <div class="row">
      <div class="d-md-none col">
        <app-notification *ngIf="notifications.projects?.length" (click)="showNotifications()" [badge]="notifications.projects?.length" ctaLabel="Aufgaben ansehen">
          Ihr Einsatz ist gefragt!
        </app-notification>
      </div>
    </div>
  </div> -->

  <div class="container mb-6">
    <app-dashboard-announcement (startClick)="briefingModal.start()"></app-dashboard-announcement>
  </div>

  <div class="container project-container">
    <div class="row mb-4 align-items-center">

      <div class="col section-title">
        <h3 class="h5 mb-0">Zuletzt angesehene Projekte</h3>
      </div>

      <!-- <div class="col-auto">

        <a class="btn btn-secondary btn-sm" (click)="briefingModal.start()">
          <i class="me-2 fa-solid fa-plus"></i> Projekt anfragen
        </a>

      </div> -->
    </div>
    <div class="row">

      <div class="col-12">

        <div class="card">

          <div class="list-group list-group-flush">

            <a *ngFor="let item of projects" routerLink="/projects/{{item.Id}}" class="list-group-item list-group-item-action">

              <div class="item-row align-items-center">
                <div class="image-col">
                  <div class="image-container">
                    <img *ngIf="item.Image" [src]="item.Image | imageSize : 80 : 50" />
                    <img *ngIf="!item.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{item.Id % 3 + 1}}.jpg" class="card-img-top" />
                  </div>
                </div>

                <div class="body-col">
                  <h4 class="h5 pe-3 mb-0">
                    <span class="oneline d-inline-block" style="max-width: 100%;">{{item.Name}} <span class="small fw-normal">m|w|d</span></span>                    
                  </h4>

                  <p class="card-text small fw-normal mb-0">
                    <span *ngIf="!item.Locations?.length">Unbekannter Standort</span>
                    <span *ngIf="item.Locations?.length == 1">{{item.Locations[0].City}}</span>
                    <span *ngIf="item.Locations?.length == 2">{{item.Locations[0].City}} + ein weiterer Standort</span>
                    <span *ngIf="item.Locations?.length > 2">{{item.Locations[0].City}} + {{ item.Locations.length - 1 }} weitere Standorte</span>
                  </p>
                </div>

                <div class="status-col">
                  <app-project-status-badge [project]="item"></app-project-status-badge>
                </div>

                <!--<div class="actions-col">
                        <span class="arrow right"></span>
                      </div>-->

              </div>
            </a>

          </div>
        </div>

        <!-- <a class="mb-5" routerLink="/projects/{{project.Id}}" *ngIf="project">

          <div class="card">

            <div class="img-container hover-zoom">
              <img *ngIf="project.Image" [src]="project.Image | imageSize : 1200 : 395" class="card-img-top" />
              <img *ngIf="!project.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{getImageIndex(project)}}.jpg" class="card-img-top" />
            </div>

            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-12">

                  <h2 class="h3 mb-1">
                    {{project.Name}} <span class="small fw-normal">m|w|d</span>
                  </h2>

                  <p class="card-text small mt-1 mb-1" *ngIf="project.Locations?.length > 0">
                    {{project.Locations[0].City}}
                    <span *ngIf="project.Locations.length == 2">+ ein weiterer Standort</span>
                    <span *ngIf="project.Locations.length > 2">+ {{ project.Locations.length - 1 }} weitere Standorte</span>
                  </p>

                  <app-project-status-badge class="mt-2 mb-1 ms-n1" [project]="project"></app-project-status-badge>
                </div>
              </div>
            </div>

            <div class="card-body py-0">
              <hr class="my-0" />
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <sc-activity-feed headline="" uri="projects/{{ project.Id }}/activities" pageSize="3" [readOnly]="true" [canLoadMore]="false">
                    <ng-template let-activity="activity">
                      <sc-activity-item [activity]="activity" [condensed]="true"></sc-activity-item>
                    </ng-template>
                  </sc-activity-feed>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row align-items-center">

                <div class="col"></div>

                <div class="col-auto" *ngIf="notificationCount == 0">
                  <a routerLink="/projects/{{project.Id}}"><span class="link-arrow right"><strong>Projekt ansehen</strong></span></a>
                </div>

                <div class="col-auto" *ngIf="notificationCount == 1">
                  <a class="btn btn-primary btn-sm" routerLink="/projects/{{project.Id}}">Eine Aufgabe</a>
                </div>

                <div class="col-auto" *ngIf="notificationCount > 1">
                  <a class="btn btn-primary btn-sm" routerLink="/projects/{{project.Id}}">{{ notifications.length }} Aufgaben</a>
                </div>
              </div>
            </div>

          </div>

        </a> -->
      </div>

    </div>

    <div class="row" *ngIf="projectCount > 1">
      <div class="col-12 d-flex justify-content-end">
        <a routerLink="/projects" routerLinkActive="d-none" class="link-arrow right mt-4">Alle Projekte</a>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 mt-5">
        <div class="card">
          <div class="card-body">
            <sc-activity-feed headline="" *ngIf="project" [user]="userService.me" uri="activities" [readOnly]="true" [condensed]="false">
              <ng-template let-activity="activity">
                <sc-activity-item [activity]="activity" [condensed]="false" [showProjectName]="true"></sc-activity-item>
              </ng-template>
            </sc-activity-feed>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>

<app-modal-briefing #briefingModal></app-modal-briefing>
